<template>
  <section class="n-section-primary" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <LibraryPopupsVideoPopup :videoUrl="currentVideoUrl" v-model:is-visible="isVisible" />
    <!-- Swiper for Mobile -->
    <div class="tw-visible tw-flex tw-w-full tw-flex-col tw-gap-7 md:tw-hidden">
      <div class="tw-flex tw-flex-col tw-items-start tw-gap-y-2 tw-px-2">
        <div v-html="vars.titleHtml" class="tw-flex tw-flex-row tw-flex-wrap tw-gap-x-4 tw-text-black"></div>
      </div>
      <Swiper
        :modules="[SwiperGrid]"
        :pagination="false"
        :slides-per-view="1"
        :loop="false"
        effect="fade"
        @slideChange="onSlideChange"
        :clickable="true"
        :watch-overflow="true"
        :breakpoints="{
          320: {
            slidesPerView: 2,
            spaceBetween: 10,
            grid: { rows: 2, fill: 'row' },
          },
        }"
        @swiper="onSwiper"
        class="tw-w-full tw-max-w-md sm:tw-max-w-lg"
      >
        <SwiperSlide
          v-for="(member, index) in members"
          :key="`ourteam-swiper-member-${index}`"
          @click="toggleTeamMemberDetailPopup(member, true)"
          class="!tw-flex !tw-h-auto tw-max-w-none tw-items-center tw-justify-center"
        >
          <div
            class="tw-group tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-start tw-gap-0 tw-rounded tw-bg-white tw-text-start tw-text-black"
          >
            <div class="tw-relative">
              <LibraryImage :src="member.photo" :h-ratio="1.5" :w-ratio="1" class="tw-inset-0 tw-z-0" />
              <div
                class="tw-absolute tw-inset-0 tw-flex tw-flex-col-reverse tw-items-center tw-bg-[--c-primary] tw-text-white tw-opacity-0 tw-duration-500 group-hover:tw-opacity-80"
              ></div>
              <div
                class="tw-absolute tw-inset-0 tw-flex tw-cursor-pointer tw-flex-row tw-items-end tw-justify-center tw-text-white tw-opacity-0 tw-duration-500 group-hover:tw-opacity-100"
              >
                <nuxt-link :external="true" class="tw-mb-7" :class="`n-${member.button_type}`"
                  ><h5>
                    {{ member.button_label }}
                  </h5></nuxt-link
                >
              </div>
            </div>
            <div class="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-start tw-gap-4 tw-p-6 tw-text-center">
              <h4 class="tw-mb-2 tw-break-words">{{ member.name }} {{ member.surname }}</h4>
              <div class="tw-mt-auto tw-flex tw-w-full tw-flex-col tw-items-center">
                <span class="!tw-font-bold tw-duration-500 group-hover:-tw-translate-y-2">{{ member.role }}</span>
                <div
                  v-show="false"
                  class="tw-hidden tw-w-full tw-items-center tw-justify-center tw-gap-2 tw-break-words tw-opacity-50 xl:tw-flex"
                >
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.8707 9.8407 10.7461 9.83487 10.627 9.86317C10.5079 9.89147 10.3992 9.95275 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"
                      fill="black"
                    />
                  </svg>
                  <p class="tw-max-w-full">{{ member.phone }}</p>
                </div>
                <div class="tw-hidden tw-items-center tw-justify-center tw-gap-2 tw-text-wrap tw-opacity-50 xl:tw-flex">
                  <span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2.66671 13.3334C2.30004 13.3334 1.98604 13.2027 1.72471 12.9414C1.46338 12.68 1.33293 12.3662 1.33338 12V4.00002C1.33338 3.63335 1.46404 3.31935 1.72538 3.05802C1.98671 2.79669 2.30049 2.66624 2.66671 2.66669H13.3334C13.7 2.66669 14.014 2.79735 14.2754 3.05869C14.5367 3.32002 14.6672 3.6338 14.6667 4.00002V12C14.6667 12.3667 14.536 12.6807 14.2747 12.942C14.0134 13.2034 13.6996 13.3338 13.3334 13.3334H2.66671ZM8.00004 8.66669L13.3334 5.33335V4.00002L8.00004 7.33335L2.66671 4.00002V5.33335L8.00004 8.66669Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <p class="tw-break-all">{{ member.email }}</p>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <div class="controls xl:!tw-hidden" :class="{ '!tw-hidden': !canPaginateBack && !canPaginateNext }">
          <div
            data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
            class="slide-left-arrow w-slider-arrow-left"
            :isDisabled="!canPaginateBack"
            @click="() => swiper.slidePrev()"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.011 3.49956C16.1418 3.6335 16.2151 3.81332 16.2151 4.00056C16.2151 4.18781 16.1418 4.36762 16.011 4.50156L8.70149 12.0001L16.011 19.4971C16.1418 19.631 16.2151 19.8108 16.2151 19.9981C16.2151 20.1853 16.1418 20.3651 16.011 20.4991C15.9474 20.5644 15.8714 20.6163 15.7874 20.6517C15.7034 20.6872 15.6132 20.7054 15.522 20.7054C15.4308 20.7054 15.3406 20.6872 15.2566 20.6517C15.1726 20.6163 15.0966 20.5644 15.033 20.4991L7.25999 12.5236C7.12347 12.3835 7.04706 12.1957 7.04706 12.0001C7.04706 11.8045 7.12347 11.6166 7.25999 11.4766L15.033 3.50106C15.0966 3.43575 15.1726 3.38383 15.2566 3.34839C15.3406 3.31294 15.4308 3.29468 15.522 3.29468C15.6132 3.29468 15.7034 3.31294 15.7874 3.34839C15.8714 3.38383 15.9474 3.43575 16.011 3.50106V3.49956Z"
                fill="#6EADAB"
              />
            </svg>
            <div class="slide-circle slider-circle-transform"></div>
          </div>
          <div
            data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
            class="slide-right-arrow w-slider-arrow-right"
            :isDisabled="!canPaginateNext"
            @click="() => swiper.slideNext()"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.98901 3.49956C7.85817 3.6335 7.78491 3.81332 7.78491 4.00056C7.78491 4.18781 7.85817 4.36762 7.98901 4.50156L15.2985 12.0001L7.98901 19.4971C7.85817 19.631 7.78491 19.8108 7.78491 19.9981C7.78491 20.1853 7.85817 20.3651 7.98901 20.4991C8.0526 20.5644 8.12864 20.6163 8.21263 20.6517C8.29661 20.6872 8.38685 20.7054 8.47801 20.7054C8.56917 20.7054 8.65941 20.6872 8.74339 20.6517C8.82738 20.6163 8.90342 20.5644 8.96701 20.4991L16.74 12.5236C16.8765 12.3835 16.9529 12.1957 16.9529 12.0001C16.9529 11.8045 16.8765 11.6166 16.74 11.4766L8.96701 3.50106C8.90342 3.43575 8.82738 3.38383 8.74339 3.34839C8.65941 3.31294 8.56917 3.29468 8.47801 3.29468C8.38685 3.29468 8.29661 3.31294 8.21263 3.34839C8.12864 3.38383 8.0526 3.43575 7.98901 3.50106V3.49956Z"
                fill="#6EADAB"
              />
            </svg>
            <div class="slide-circle right"></div>
          </div>
        </div>
      </Swiper>

      <nuxt-link :external="true" :to="vars.buttonLinkUrl" :class="`n-${vars.buttonTypeText} tw-self-center`">
        {{ vars.buttonLabelText }}</nuxt-link
      >
    </div>

    <!-- Flex for Desktop -->
    <div
      class="tw-mb-8 tw-hidden tw-w-full tw-flex-col tw-items-start tw-gap-y-2 tw-self-start md:tw-flex md:tw-flex-row md:tw-items-center md:tw-justify-between"
    >
      <div v-html="vars.titleHtml" class="tw-flex tw-flex-row tw-flex-wrap tw-text-black"></div>
      <nuxt-link :external="true" :to="vars.buttonLinkUrl" :class="`n-${vars.buttonTypeText}`">
        {{ vars.buttonLabelText }}</nuxt-link
      >
    </div>
    <div
      class="tw-hidden tw-w-full tw-text-black md:tw-flex md:tw-flex-wrap md:tw-justify-center md:tw-gap-6 xl:tw-items-stretch"
    >
      <div
        v-for="(member, index) in members"
        :key="`ourteam-member-${index}`"
        @click="toggleTeamMemberDetailPopup(member, true)"
        class="tw-group tw-relative tw-flex tw-min-h-[520px] tw-flex-[0_0_48%] tw-flex-col tw-justify-start tw-gap-0 tw-rounded tw-bg-white tw-text-start xl:tw-h-auto xl:tw-w-[295px] xl:tw-flex-initial 2xl:tw-min-h-[680px] 2xl:tw-w-[360px]"
      >
        <div class="tw-relative">
          <LibraryImage :src="member.photo" :h-ratio="1.5" :w-ratio="1" class="tw-inset-0 tw-z-0" />
          <div
            class="tw-absolute tw-inset-0 tw-flex tw-flex-col-reverse tw-items-center tw-bg-[--c-primary] tw-text-white tw-opacity-0 tw-duration-500 group-hover:tw-opacity-80"
          ></div>
          <div
            class="tw-absolute tw-inset-0 tw-flex tw-cursor-pointer tw-flex-row tw-items-end tw-justify-center tw-text-white tw-opacity-0 tw-duration-500 group-hover:tw-opacity-100"
          >
            <nuxt-link :external="true" class="tw-mb-7" :class="`n-${member.button_type}`"
              ><h5>
                {{ member.button_label }}
              </h5></nuxt-link
            >
          </div>
        </div>
        <div class="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-start tw-gap-4 tw-p-6 tw-text-center">
          <h4 class="tw-mb-2 tw-break-words">{{ member.name }} {{ member.surname }}</h4>
          <div class="tw-mt-auto tw-flex tw-w-full tw-flex-col tw-items-center">
            <span class="!tw-font-bold tw-duration-500 group-hover:-tw-translate-y-2">{{ member.role }}</span>
            <nuxt-link
              :external="true"
              :to="`tel: ${member.phone}`"
              class="n-link tw-hidden tw-w-full tw-max-w-fit tw-items-center tw-justify-center tw-gap-2 tw-text-base tw-opacity-50 xl:tw-flex"
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.8707 9.8407 10.7461 9.83487 10.627 9.86317C10.5079 9.89147 10.3992 9.95275 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"
                  fill="black"
                />
              </svg>
              <p class="tw-line-clamp-1 tw-break-all">{{ member.phone }}</p>
            </nuxt-link>
            <nuxt-link
              :external="true"
              :to="`mailto: ${member.email}`"
              class="n-link tw-hidden tw-max-w-fit tw-items-center tw-justify-center tw-gap-2 tw-text-wrap tw-text-base tw-opacity-50 xl:tw-flex"
            >
              <span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.66671 13.3334C2.30004 13.3334 1.98604 13.2027 1.72471 12.9414C1.46338 12.68 1.33293 12.3662 1.33338 12V4.00002C1.33338 3.63335 1.46404 3.31935 1.72538 3.05802C1.98671 2.79669 2.30049 2.66624 2.66671 2.66669H13.3334C13.7 2.66669 14.014 2.79735 14.2754 3.05869C14.5367 3.32002 14.6672 3.6338 14.6667 4.00002V12C14.6667 12.3667 14.536 12.6807 14.2747 12.942C14.0134 13.2034 13.6996 13.3338 13.3334 13.3334H2.66671ZM8.00004 8.66669L13.3334 5.33335V4.00002L8.00004 7.33335L2.66671 4.00002V5.33335L8.00004 8.66669Z"
                    fill="black"
                  />
                </svg>
              </span>
              <p class="tw-line-clamp-1 tw-break-all">{{ member.email }}</p>
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
    <popup v-if="selectedMember && popupConfig.isActive" :config="popupConfig" custom-width="100%" class="!tw-z-50">
      <template #content>
        <div
          class="tw-grid tw-h-full tw-w-full tw-grid-cols-12 tw-gap-y-4 tw-py-4 tw-text-black lg:tw-gap-x-12 xl:tw-gap-20"
        >
          <div
            class="tw-col-span-12 tw-flex tw-items-center tw-justify-center md:tw-col-span-8 md:tw-col-start-3 lg:tw-col-span-5"
          >
            <div>
              <div style="position: relative; height: 100%; width: 100%">
                <NuxtImg :src="selectedMember.video_cover" :alt="selectedMember.name" />

                <div
                  class="tw-absolute tw-top-0 tw-z-10 tw-h-full tw-w-full"
                  v-if="selectedMember.video"
                  @click="((isVisible = true), (currentVideoUrl = selectedMember.video))"
                >
                  <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
                    <svg width="80" height="80" viewBox="0 0 162 162" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="81" cy="81" r="81" fill="white" />
                      <polygon points="65,45 115,81 65,117" fill="black" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tw-col-span-12 tw-flex tw-w-full tw-flex-col tw-justify-center tw-gap-2 md:tw-col-start-3 lg:tw-col-span-7 xl:tw-gap-5"
          >
            <h2>{{ selectedMember.popup_title }}</h2>

            <span>{{ selectedMember.role }}</span>

            <div class="tw-py-4">
              <nuxt-link
                :external="true"
                v-if="selectedMember.email"
                :to="`mailto: ${selectedMember.email}`"
                class="n-link tw-flex tw-max-w-fit tw-items-center tw-gap-1 tw-pb-3 tw-text-base"
              >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.66671 13.3334C2.30004 13.3334 1.98604 13.2027 1.72471 12.9414C1.46338 12.68 1.33293 12.3662 1.33338 12V4.00002C1.33338 3.63335 1.46404 3.31935 1.72538 3.05802C1.98671 2.79669 2.30049 2.66624 2.66671 2.66669H13.3334C13.7 2.66669 14.014 2.79735 14.2754 3.05869C14.5367 3.32002 14.6672 3.6338 14.6667 4.00002V12C14.6667 12.3667 14.536 12.6807 14.2747 12.942C14.0134 13.2034 13.6996 13.3338 13.3334 13.3334H2.66671ZM8.00004 8.66669L13.3334 5.33335V4.00002L8.00004 7.33335L2.66671 4.00002V5.33335L8.00004 8.66669Z"
                    fill="black"
                  />
                </svg>

                {{ selectedMember.email }}
              </nuxt-link>

              <nuxt-link
                :external="true"
                v-if="selectedMember.phone"
                :to="`tel: ${selectedMember.phone}`"
                class="n-link tw-flex tw-max-w-fit tw-items-center tw-gap-1 tw-pb-3 tw-text-base"
              >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.8707 9.8407 10.7461 9.83487 10.627 9.86317C10.5079 9.89147 10.3992 9.95275 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"
                    fill="black"
                  />
                </svg>

                {{ selectedMember.phone }}
              </nuxt-link>
            </div>
            <div v-html="selectedMember.description" class="tw-flex tw-flex-col tw-gap-2"></div>
            <nuxt-link
              class="tw-mt-6 tw-max-w-fit xl:tw-mt-8"
              :class="`n-${selectedMember.popup_button_type}`"
              :external="true"
              :to="`mailto: ${selectedMember.popup_button_link}`"
            >
              {{ selectedMember.popup_button_label }}
            </nuxt-link>
          </div>
        </div>
      </template>
    </popup>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import Popup from '~/components/common/popup/Popup.vue';

export default defineNuxtComponent({
  name: 'OurTeam',

  mixins: [ComponentMixin],

  components: { Popup },

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      selectedMember: null as any | null,
      popupConfig: {
        isActive: false,
      },
      isVisible: false,
      currentVideoUrl: '',
    };
  },

  computed: {
    members(): any[] {
      return this.groupedVariables?.members || [];
    },
  },

  methods: {
    toggleTeamMemberDetailPopup(member: any | null = null, value = false): void {
      if (member?.popup_title) {
        this.selectedMember = member;
        this.popupConfig.isActive = value;
      }
    },

    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange(): void {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },

  mounted() {
    this.onSlideChange();
  },
});
</script>
