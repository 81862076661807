<template>
  <section
    v-if="variants.v1 === variant"
    class="n-section-primary tw-bg-cover tw-bg-center"
    :style="`background-image: url(${vars.backgroundImage})`"
  >
    <div
      class="tw-absolute tw-inset-0 tw-opacity-80"
      :style="`background-color: var(--c-${vars.backgroundColor});`"
    ></div>
    <h3 class="n-text-shadow tw-z-10 tw-text-center tw-text-white">
      {{ vars.titleText }}
    </h3>
  </section>

  <section v-if="variants.v2 === variant" class="n-section-primary tw-bg-cover tw-bg-center">
    <div class="tw-absolute tw-inset-0 tw-opacity-100" :style="{ backgroundColor: 'var(--c-tertiary)' }"></div>
    <h2 v-if="vars.titleText" class="n-title-primary tw-mb-6 tw-max-w-[1000px] tw-text-center md:tw-mb-11">
      {{ vars.titleText }}
    </h2>
    <div
      v-if="vars.titleHtml"
      class="tw-flex tw-flex-wrap tw-justify-center tw-text-center lg:tw-gap-4"
      v-html="vars.titleHtml"
    ></div>
    <div class="tw-mt-2 tw-flex tw-flex-row tw-justify-center tw-gap-3">
      <nuxt-link
        v-for="(button, index) in buttons"
        :key="`card-btn-${index}`"
        :class="`n-${button.type}`"
        :to="button.link"
        :external="true"
      >
        {{ button.label }}
      </nuxt-link>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'TextBanner',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
